import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import copyItem from './copyItem';
import assetPutAway from '../../../assetPutAway'
import JsBarcode from 'jsbarcode';

export default {
  name: 'grnAssetDataModal',
  components: { DatePicker,copyItem,assetPutAway},
  props: ['rowData','currentLoc','locId'],
  watch: {
    currentPage: function() {
      this.generateAsset();
    },
    perPage: function() {
      this.currentPage = 1;
      this.generateAsset();
    },
    tags() {
      this.adjustTagsWidth();
    }
  },
  data() {
    return {
      isSuccess: false,
      showAlert: false,
      responseMsg: '',
      totalElements: null,
      currentPage: 1,
      perPage: 1,
      pageOptions: commonHelper.getPageOption(),
      loader: false,
      isOpen: false,
      editMode: false,
      unsubscribe: null,
      assetData: null,
      alreadyCreated: null,
      showCopyModal: false,
      grnassets: [
        {
          label: 'Purchase Details :-',
          class: 'purchase'
        },
        {
          key: 'le_name',
          label: 'Legal Entity',
          value: null,
          isCalender: 0,
          editsFlag: 1
        },
        {
          key: 'ou_name',
          label: 'Operating Unit',
          value: null,
          isCalender: 0,
          editsFlag: 1
        },
        {
          key: 'cost_center',
          label: 'Cost Center',
          value: null,
          isCalender: 0,
          editsFlag: 1
        },
        {
          key: 'vendor_name',
          label: 'Vendor Name',
          value: null,
          isCalender: 0,
          editsFlag: 1
        },
        {
          key: 'po_number',
          label: 'PO Number',
          value: null,
          isCalender: 0,
          editsFlag: 1
        },
        {
          key: 'po_date',
          label: 'PO Date',
          value: null,
          isCalender: 0,
          editsFlag: 1
        },
        // {
        //   key: 'qty',
        //   label: ' PO Quantity',
        //   value: null,
        //   isCalender: 0,
        //   editsFlag: 1
        // },
        {
          key: 'item_code',
          label: 'Item Code',
          value: null,
          isCalender: 0,
          editsFlag: 1
        },
        {
          key: 'item_desc',
          label: 'Item Description',
          value: null,
          isCalender: 0,
          editsFlag: 1
        },
        {
          key: 'price',
          label: 'Price',
          value: null,
          isCalender: 0,
          editsFlag: 1
        },
        {
          key: 'grn',
          label: 'GRN Details :-',
          class: 'grnDetails'
        },
        {
          key: 'grn_num',
          label: 'GRN Number',
          value: null,
          isCalender: 0,
          editsFlag: 1
        },
        {
          key: 'grn_date',
          label: 'GRN Date',
          value: null,
          isCalender: 0,
          editsFlag: 1
        },
        {
          key: 'fa_cat',
          label: 'FA Category',
          value: null,
          isCalender: 0,
          editsFlag: 1
        },
        {
          key: 'hsn_code',
          label: 'HSN / SAC code',
          value: null,
          isCalender: 0,
          editsFlag: 1
        },
        {
          label: 'Asset Details:-'
        },
        {
          key: 'date_received',
          label: 'Date Received',
          value: null,
          isCalender: 1,
          editsFlag: 2,
          classes: 'mandatory'
        },
        {
          key: 'invoice_date',
          label: 'Invoice Date',
          value: null,
          isCalender: 1,
          editsFlag: 2,
          classes: 'mandatory'
        },
        {
          key: 'serial_number',
          label: 'Serial Number',
          value: null,
          isCalender: 6,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'hdd_ssd',
          label: 'HDD/SSD',
          value: null,
          isCalender: 0,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'wireless_mac_address',
          label: 'Wireless Mac Address',
          value: null,
          isCalender: 0,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'lan_mac_address',
          label: 'LAN Mac Address',
          value: null,
          isCalender: 0,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'end_of_warranty',
          label: 'End Of Warranty',
          value: null,
          isCalender: 5,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'extended_warranty',
          label: 'Extended Warranty',
          value: null,
          isCalender: 5,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'type_of_warranty',
          label: 'Type Of Warranty',
          value: null,
          meaning: null,
          isCalender: 7,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'notes',
          label: 'Notes',
          value: null,
          isCalender: 0,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'tags',
          label: 'Tags',
          value: [],
          isCalender: 2,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'manufacturer',
          label: 'Manfacturer',
          value: null,
          meaning: null,
          isCalender: 8,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'model',
          label: 'Model',
          value: null,
          meaning: null,
          isCalender: 9,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'processor',
          label: 'Processor',
          value: null,
          meaning: null,
          isCalender: 10,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'color',
          label: 'Color',
          value: null,
          isCalender: 0,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'group_item_category',
          label: 'Group Item Category',
          value: null,
          isCalender: 0,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'department',
          label: 'Department',
          value: null,
          meaning: null,
          isCalender: 4,
          editsFlag: 2,
          classes: null
        }
        // {
        //   key: 'le_id',
        //   value: null
        // }
      ],
      parent_value_set_id: null,
      assetId: null,
      copyData: null,
      documentNo: null,
      showValueSetModal: false,
      itemType: null,
      assetItem: null,
      itemId: null,
      data: null,
      checkFlag: null,
      checkedFlag: null,
      regexPattern: /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})|([0-9a-fA-F]{4}\.[0-9a-fA-F]{4}\.[0-9a-fA-F]{4})$/,
      getDate: null,
      modelValueSetId: null,
      getRes: null,
      showHideStowModalFlag:false,
      barcode:null,
    };
  },
  mounted() {
    if (this.rowData) {
      this.alreadyCreatedQty();
      this.copyData = this.rowData;
      // this.mapRowData(this.rowData);
      this.generateAsset();
    } else {
      this.editMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          // this.addEditGrnAsset();
          if (this.getRes === undefined) {
            // alert('Create asset first');
            this.$bvToast.toast('Create asset first', {
              title: 'Alert',
              variant: 'danger',
              solid: true
            });
          } else {
            this.updateAsset();
          }
        }
        if (actionName === 'update') {
          this.editMode = !this.editMode;
        }
      }
    });
  },
  computed: {
    // limitedItems() {
    //   return this.grnassets.slice(0, this.grnassets.length - 1);
    // }
    disabledDates() {
      return date => {
        const currentDate = new Date();
        const previousDate = new Date(this.grnassets[6].value);
        previousDate.setHours(0, 0, 0, 0);
        return date > currentDate || date < previousDate;
      };
    }
  },
  methods: {
    setDefaultModel() {
      const modelValue = commonHelper.getValuesetData(
        appStrings.VALUESETTYPE.FA_ASSET_MANUFACTURER,
        this.grnassets[27].value
      );
      modelValue.then(key => {
        this.modelValueSetId = key[0].value_set_dtl_id
      });
    },
    updateAsset() {
      if (this.itemType === 'ASSET' && this.assetItem) {
        for (let i = 0; i < this.grnassets.length; i++) {
          if (
            this.grnassets[i].classes === 'mandatory' &&
            (!this.grnassets[i].value || this.grnassets[i].value.length == 0)
          ) {
            // alert(this.grnassets[i].label + ' ' + 'is missing');
            this.$bvToast.toast(this.grnassets[i].label + ' ' + 'is missing', {
              title: 'Alert',
              variant: 'danger',
              solid: true
            });
          } else if (
            this.grnassets[i].classes === 'mandatory' &&
            (this.grnassets[i].value || this.grnassets[i].value.length !== 0)
          ) {
            this.checkedFlag++;
          }
        }

        if (
          this.grnassets[18].value &&
          this.checkFlag === this.checkedFlag &&
          this.grnassets[18].value.length >= 5
        ) {
          if (this.grnassets[20].value && this.grnassets[21].value) {
            this.checkedFlag = null;
            this.validateBothMac();
          } else if (this.grnassets[20].value && !this.grnassets[21].value) {
            this.checkedFlag = null;
            this.validateMacAddressWireless();
          } else if (this.grnassets[21].value && !this.grnassets[20].value) {
            this.checkedFlag = null;
            this.validateMacAddressWired();
          } else {
            this.checkedFlag = null;
            this.addEditGrnAsset();
          }
        } else {
          // alert('Serial Number length should be of 5-20 characters only');
          if(this.grnassets[18].value.length<5&&this.grnassets[18].value.length>0 ||this.grnassets[18].value.length>20){
          this.$bvToast.toast('Serial Number length should be of 5-20 characters only', {
            title: 'Alert',
            variant: 'danger',
            solid: true
          });
          this.$nextTick(() => {
            this.$refs.serialInput[0].focus();
        });
          this.checkedFlag = null;
        }
        }
      } else if (this.itemType === 'CONSUMABLE' && this.assetItem) {
        for (let i = 0; i < this.grnassets.length; i++) {
          if (
            this.grnassets[i].classes === 'mandatory' &&
            (!this.grnassets[i].value || this.grnassets[i].value.length == 0)
          ) {
            // alert(this.grnassets[i].label + ' ' + 'is missing');
            this.$bvToast.toast(this.grnassets[i].label + ' ' + 'is missing', {
              title: 'Alert',
              variant: 'danger',
              solid: true
            });
          } else if (
            this.grnassets[i].classes === 'mandatory' &&
            (this.grnassets[i].value || this.grnassets[i].value.length !== 0)
          ) {
            this.checkedFlag++;
          }
        }

        if (this.checkFlag === this.checkedFlag) {
          if (this.grnassets[20].value && this.grnassets[21].value) {
            this.checkedFlag = null;
            this.validateBothMac();
          } else if (this.grnassets[20].value && !this.grnassets[21].value) {
            this.checkedFlag = null;
            this.validateMacAddressWireless();
          } else if (this.grnassets[21].value && !this.grnassets[20].value) {
            this.checkedFlag = null;
            this.validateMacAddressWired();
          } else if (this.grnassets[18].value) {
            if (this.grnassets[18].value.length >= 5) {
              this.checkedFlag = null;
              this.addEditGrnAsset();
            } else {
              // alert('Serial Number length should be of 5-20 characters only');
              if(this.grnassets[18].value.length<5&&this.grnassets[18].value.length>0 ||this.grnassets[18].value.length>20){
              this.$bvToast.toast('Serial Number length should be of 5-20 characters only', {
                title: 'Alert',
                variant: 'danger',
                solid: true
              });
              this.$nextTick(() => {
                this.$refs.serialInput[0].focus();
            });
              this.checkedFlag = null;
          }
            }
          } else {
            this.checkedFlag = null;
            this.addEditGrnAsset();
          }
        } else if (!this.checkFlag) {
          this.checkedFlag = null;
          this.addEditGrnAsset();
        } else {
          this.checkedFlag = null;
        }
      }
    },

    validateMacAddressWireless() {
      const check = appStrings.specialChar.some(i =>
        this.grnassets[20].value.includes(i)
      );
      if (check) {
        // alert("Special characters are not allowed except ':' or '-'");
        this.$bvToast.toast("Special characters are not allowed except ':' or '-'", {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
        this.$nextTick(() => {
          this.$refs.myInputs[15].focus();
      });
      } else {
        const serialMacAddressWirelessLength = this.grnassets[20].value;
        if (
          this.regexPattern.test(serialMacAddressWirelessLength) &&
          serialMacAddressWirelessLength.length === 17
        ) {
          this.addEditGrnAsset();
        } else {
          // alert(
          //   "Wireless Mac Address format should be of length 17 including '-' or ':' and a-f/A-F"
          // );
          this.$bvToast.toast("Wireless Mac Address format should be of length 17 including '-' or ':' and a-f/A-F", {
            title: 'Alert',
            variant: 'danger',
            solid: true
          });
        }
      }
    },
    validateMacAddressWired() {
      const check = appStrings.specialChar.some(i =>
        this.grnassets[21].value.includes(i)
      );
      if (check) {
        // alert("Special characters are not allowed except ':' or '-'");
        this.$bvToast.toast("Special characters are not allowed except ':' or '-'", {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
        this.$nextTick(() => {
          this.$refs.myInputs[16].focus();
      });
      } else {
        const serialMacAddressWiredLength = this.grnassets[21].value;
        if (
          this.regexPattern.test(serialMacAddressWiredLength) &&
          serialMacAddressWiredLength.length === 17
        ) {
          this.addEditGrnAsset();
        } else {
          // alert(
          //   "LAN Mac Address format should be of length 17 including '-' or ':' and a-f/A-F"
          // );
          this.$bvToast.toast("LAN Mac Address format should be of length 17 including '-' or ':' and a-f/A-F", {
            title: 'Alert',
            variant: 'danger',
            solid: true
          });
          this.$nextTick(() => {
            this.$refs.myInputs[16].focus();
        });
        }
      }
    },
    validateBothMac() {
      const check = appStrings.specialChar.some(i =>
        this.grnassets[20].value.includes(i)
      );
      if (check) {
        // alert("Special characters are not allowed except ':' or '-'");
        this.$bvToast.toast("Special characters are not allowed except ':' or '-'", {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
        this.$nextTick(() => {
          this.$refs.myInputs[15].focus();
      });
      } else {
        const serialMacAddressWirelessLength = this.grnassets[20].value;
        if (
          this.regexPattern.test(serialMacAddressWirelessLength) &&
          serialMacAddressWirelessLength.length === 17
        ) {
          this.validateMacAddressWired();
        } else {
          // alert(
          //   "Wireless Mac Address format should be of length 17 including '-' or ':' and a-f/A-F"
          // );
          this.$bvToast.toast("Wireless Mac Address format should be of length 17 including '-' or ':' and a-f/A-F", {
            title: 'Alert',
            variant: 'danger',
            solid: true
          });
          this.$nextTick(() => {
            this.$refs.myInputs[15].focus();
        });
        }
      }
    },
    getAssetItem() {
      this.grnassets.map(item => (item.classes = null));
      this.checkFlag = null;
      const payload = {
        item_id: this.itemId
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getAssetItemById', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.data = resp.data.data;
            for (let i in this.data) {
              for (let j = 0; j < this.grnassets.length; j++) {
                if (i === this.grnassets[j].key && this.data[i] === true) {
                  this.grnassets[j].classes = 'mandatory';
                  this.checkFlag++;
                }
              }
            }
            this.grnassets[16].classes = 'mandatory'
            this.checkFlag++;
            this.grnassets[17].classes = 'mandatory'
            this.checkFlag++;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    mapRowData(rowData) {
      if (rowData !== null) {
        for (let i = 0; i < this.grnassets.length; i++) {
          this.grnassets[i].value = rowData[this.grnassets[i].key];
        }
      }
    },
    alreadyCreatedQty() {
      const payload = {
        po_number: this.rowData.po_number,
        grn_num: this.rowData.grn_num,
        item_code: this.rowData.item_code
      };
      this.loader = true;
      this.$store
        .dispatch('assets/alreadyCreatedQty', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.alreadyCreated = resp.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getTags(id) {
      const payload = {
        asset_id: id
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getAssetTags', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.grnassets[26].value = [];
            resp.data.data.map(item => {
              this.grnassets[26].value.push(item);
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    generateAsset() {
      const payload = {
        page: this.currentPage - 1,
        limit: 1,
        po_number: this.rowData.po_number,
        // grn_num: this.rowData.grn_num,
        item_code: this.rowData.item_code
      };
      this.loader = true;
      this.$store
        .dispatch('assets/generateAsset', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page[0];
            this.getRes = result;
            this.mapRowData(result);
            this.documentNo = resp.data.data.page[0].doc_num;
            this.totalElements = resp.data.data.total_elements;
            this.assetId = resp.data.data.page[0].asset_id;
            this.itemType = resp.data.data.page[0].item_type_meaning;
            this.assetItem = resp.data.data.page[0].item_name;
            this.itemId = resp.data.data.page[0].fa_asset_item_id;
            this.grnassets[32].meaning =
              resp.data.data.page[0].department_meaning;
            this.grnassets[27].meaning =
              resp.data.data.page[0].manufacturer_name_meaning;
            this.grnassets[28].meaning = resp.data.data.page[0].model_meaning;
            this.grnassets[24].meaning =
              resp.data.data.page[0].type_of_warranty_meaning;
            this.getDate = resp.data.data.page[0].date_received;
            this.grnassets[29].meaning=resp.data.data.page[0].processor;
            this.grnassets[29].value=resp.data.data.page[0].processor_id;
            this.getTags(this.assetId);
            this.getAssetItem();
            this.setDefaultModel();
            if(this.itemType==="ASSET"){
              setTimeout(() => {
                this.showBarcode(resp.data.data.page[0].bar_code)
              }, 0);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditGrnAsset() {
      if (
        (this.grnassets[22].value || this.grnassets[23].value) &&
        !this.grnassets[16].value
      ) {
        // alert('Please fill Date Received');
        this.$bvToast.toast('Please fill Date Received', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
        this.$nextTick(() => {
          this.$refs.dateInput[0].focus();
      });
      this.grnassets[22].value=null;
      this.grnassets[23].value=null;
      } else if(this.grnassets[27].value && !this.grnassets[28].value){
        alert("Please select a Model")
        this.$bvToast.toast('Please select a Model', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
        this.$nextTick(() => {
          this.$refs.modelInput[0].focus();
      });
      }    else if(this.grnassets[20].value == this.grnassets[21].value && this.grnassets[20].value!==null && this.grnassets[21].value!==null){
        this.$bvToast.toast("Wireless Mac Address and LAN Mac Address should be unique", {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
        this.$nextTick(() => {
          this.$refs.myInputs[15].focus();
      });
      }
      else {
        const payload = {
          asset_id: this.assetId,
          color: null,
          department: null,
          end_of_warranty: null,
          extended_warranty: null,
          date_received: null,
          hdd_ssd: null,
          lan_mac_address: null,
          wireless_mac_address: null,
          manufacturer: null,
          group_item_category: null,
          model: null,
          notes: null,
          serial_number: null,
          tags: [],
          tags_status: null,
          type_of_warranty: null,
          updated_by: null,
          invoice_date:null
        };
        for (let k in payload) {
          for (let i = 0; i < this.grnassets.length; i++) {
            if (k === this.grnassets[i].key) {
              payload[k] = this.grnassets[i].value;
            }
          }
        }
        this.loader = true;
        this.$store
          .dispatch('assets/addEditGrnAsset', payload)
          .then(response => {
            this.loader = false;
            // this.showAlert = true;
            if (response.status === 200) {
              this.editMode = false;
              // this.isSuccess = true;
              // this.responseMsg = response.data.message;
              this.$bvToast.toast(response.data.message, {
                title: 'Alert',
                variant: 'success',
                solid: true
              });
            } else {
              this.isSuccess = false;
              // this.responseMsg = response.response.data.message;
              this.$bvToast.toast(response.data.message, {
                title: 'Alert',
                variant: 'danger',
                solid: true
              });
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      }
    },
    showHideCopyModal(flag) {
      this.showCopyModal = flag;
    },
    tempFunc(item) {
      if (item) {
        this.showHideCopyModal(false);
        // this.grnassets[16].value = item.date_received;
        // this.grnassets[17].value = item.data.invoice_date;
        // this.grnassets[18].value = item.data.serial_number;
        this.grnassets[19].value = item.data.hdd_ssd;
        this.grnassets[20].value = item.data.mac_address_wireless;
        this.grnassets[21].value = item.data.mac_address_wired;
        this.grnassets[22].value = item.data.end_of_warranty;
        this.grnassets[23].value = item.data.extended_warranty;
        this.grnassets[24].value = item.data.type_of_warranty;
        this.grnassets[24].meaning = item.data.type_of_warranty_meaning;
        this.grnassets[25].value = item.data.notes;
        this.getTags(item.asstId); // value of tags which is 25th index
        this.grnassets[27].value = item.data.manufacturer;
        this.grnassets[27].meaning = item.data.manufacturer_name_meaning;
        this.grnassets[28].value = item.data.model;
        this.grnassets[28].meaning = item.data.model_meaning;
        this.grnassets[29].value = item.data.processor_id;
        this.grnassets[29].meaning = item.data.processor;
        this.grnassets[30].value = item.data.color;
        this.grnassets[31].value = item.data.group_item_category;
        this.grnassets[32].value = item.data.department;
        this.grnassets[32].meaning = item.data.department_meaning;
        this.setDefaultModel()
      }
    },
    openManufacturerBaseModelType() {
      if (this.grnassets[27].value) {
        this.openValueSetModal('FA_ASSET_MODEL_TYPE');
      } else {
        // alert('Please Select Type Of Manufacturer');
        this.$bvToast.toast('Please Select Type Of Manufacturer', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      }
    },
    openProcessorType(){
      if (this.assetItem=="LAPTOP" || this.assetItem=="DESKTOP") {
        this.openValueSetModal('FA_ITEM_PROCESSOR');
      }
      else{
        this.$bvToast.toast('Asset Item can be either LAPTOP or DESKTOP', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
        this.$nextTick(() => {
          this.$refs.assetItemInput.focus();
      });
      }
    },
    openValueSetModal(vsetCode) {
      this.parent_value_set_id = null;
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      if (this.vsetCode === appStrings.VALUESETTYPE.FA_ASSET_MODEL_TYPE) {
        this.parent_value_set_id = this.modelValueSetId;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.FA_ASSET_TAGS) {
        this.grnassets[26].value.push(item.value_code);
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FA_DEPARTMENT) {
        this.grnassets[32].value = item.value_code;
        this.grnassets[32].meaning = item.value_meaning;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.ASSET_TYPE_OF_WARRANTY
      ) {
        this.grnassets[24].value = item.value_code;
        this.grnassets[24].meaning = item.value_meaning;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.FA_ASSET_MANUFACTURER
      ) {
        this.grnassets[27].value = item.value_code;
        this.grnassets[27].meaning = item.value_meaning;
        this.modelValueSetId=item.value_set_dtl_id;
        this.grnassets[28].value = null;
        this.grnassets[28].meaning = null;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.FA_ASSET_MODEL_TYPE
      ) {
        this.grnassets[28].value = item.value_code;
        this.grnassets[28].meaning = item.value_meaning;
      }
      else if(this.vsetCode === appStrings.VALUESETTYPE.FA_ITEM_PROCESSOR)
      {
        this.grnassets[29].value = item.value_set_dtl_id;
        this.grnassets[29].meaning = item.value_code;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      switch (vsetCode) {
        case 'ASSET_TYPE_OF_WARRANTY':
          this.grnassets[24].value = null;
          this.grnassets[24].meaning = null;
          break;
        case 'FA_ASSET_MANUFACTURER':
          this.grnassets[27].value = null;
          this.grnassets[27].meaning = null;
          break;
        case 'FA_ASSET_MODEL_TYPE':
          this.grnassets[28].value = null;
          this.grnassets[28].meaning = null;
          break;
          case 'FA_ITEM_PROCESSOR':
                this.grnassets[29].value = null;
                this.grnassets[29].meaning = null;
                break; 
      }
    },
    removeTags(tags, tag) {
      const index = tags.indexOf(tag);
      if (index !== -1) {
        tags.splice(index, 1);
      }
    },
    adjustTagsWidth() {
      const tagsWidth = this.$refs.tags.$el.scrollWidth;
      this.$refs.tags.$el.style.width = `${tagsWidth}px`;
    },

    containsSpecialCharacters(inputString) {
      const specialCharactersPattern = /[!@#$%^&*()_+{}[\]<>,.?~\\/-]/;

      let res = specialCharactersPattern.test(inputString);
      if (res === true) {
        // alert("Special characters are not allowed except ':' or '-'");
        this.$bvToast.toast("Special characters are not allowed except ':' or '-'", {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      }
    },

    disabledDatesWarranty(date) {
      return commonHelper.disabledDates(date, this.grnassets[16].value);
    },

    dateFunc() {
      if (!this.grnassets[16].value) {
        this.$bvToast.toast('Select Date Received first', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
        this.$nextTick(() => {
          this.$refs.dateInput[0].focus();
      });
      }
    },
    showHideStowModal(flag){
      this.showHideStowModalFlag=flag
    },
    showBarcode(barco){
        this.barcode=barco
          JsBarcode("#barcode", this.barcode, {
            format: 'CODE128',
            displayValue: true,
            height: 100,
            width: 2.8,
            fontSize:30,
          });
        // setTimeout(() => {
        //   this.detailsAssetAuditData = JSON.parse(
        //     JSON.stringify(this.detailsAssetAuditData)
        //   );
        // }, 0);
       
    },
    generateBarcode(){
       // Generate the barcode
       JsBarcode("#barcode", this.barcode, {
        format: 'CODE128',
        displayValue: true,
        height: 100,
        width: 2.8,
        fontSize:30,
      });

      // Get the SVG content
      const svgContent = document.getElementById("barcode").outerHTML;

      const barcodeNumber = this.$refs.logo.innerText;
      // Open a new window to print
      const printWindow = window.open('', '_blank');

      // Write the printable content to the window
      printWindow.document.write(`
        <html>
          <head>
            <title>Barcode Print</title>
            <style>
              @media print {
                body { margin: 0; }
              }
              @page { size: auto; }
            </style>
          </head>
          <body>
            <div style="text-align: center">
              <h1 style="font-weight: bold; padding-bottom:0px; margin-bottom:30px; font-size:xxx-large">${barcodeNumber}</h1>
              <br>
              ${svgContent}
            </div>
          </body>
        </html>
      `);

      // Print the window
      printWindow.print();
      printWindow.close();
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
