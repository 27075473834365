import assetStatusUpdate from "./assetStatusUpdate";
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import responseModal from "../../../../shared/responseModal";
import { required } from 'vuelidate/lib/validators';
import assetEmployeeDetails from "./assetEmployeeDetails";


// import commonHelper from '@/app/utility/common.helper.utility';

export default {
  name: 'assetStatusUpdate',
  props:['assetData','rowData','locId'],
  components:{assetStatusUpdate,responseModal,assetEmployeeDetails},
  watch: {
    currentPage: function() {
        this.getAssetStatus();
    },
    perPage: function() {
      this.currentPage = 1;
        this.getAssetStatus();
    }
  },
  data() {
    return {
      currentPage: 1,
      totalRows:null,
      isSuccess:false,
      showAlert: false,
      responseMsg: '',
      perPage: commonHelper.perPageRecordData,
      pageOptions: commonHelper.getPageOption(),
      loading: false,
      loader:false,
      siteName:null,
      locSearch:null,
      status:null,
      department:{
        text:null,
        value:null
      },
      isOpen: false,
      showValueSetModal:false,
      currentLoc: {
        text: null,
        value: null
      },
      assetStatusUpdateData: [
        {status:'AVAILABLE',
         party_details:"Lenovo",
        remarks:"Working condition assets"}
      ],
      assetStatusUpdateFields: [
        {
          key: 'status',
          class:"itemsAlign"
        },
        {
            key: 'party_details',
            class:"itemsAlign"
          },
        {
            key: 'remarks',
            class: 'itemsAlign'
        }
      ],
      statusHistoryData:[],
      statusHistoryFields:[],
      assetNum:null,
      serialNo:null,
      selectStatus:{
        text:null,
        value:null
      },
      userName:null,
      visFlag:false,
      statusModalFlag:false,
      statusAssetData:[],
      statusAssetFields:[
       { 
        key:'value_code'
        },
        {
          key:'value_meaning'
        }
      ],
      searchText:null,
      employeeId:null,
      remarks:null,
      emailId:null,
      empType:'true',
      productName:null,
      productId:null,
      payload:{},
      regexPattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      emailFlag:false,
      ticketNumber:null,
      showHideEmployeeDetailsModalFlag:false
    }
  },
  validations: {
    selectStatus: {
        text:{required},
        value: { required }
    }
  },
  mounted() {
      this.status=this.assetData.assetStatus
    if(this.rowData){
      this.serialNo=this.rowData.serial_no
      this.assetNum=this.rowData.doc_num
    }
  
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && !this.showHideEmployeeDetailsModalFlag) {
          this.postAssetStatus();
        }
      }
    });
  },
  methods: {
    // openValueSetModal(vsetCode) {
    //     this.vsetCode = vsetCode;
    //     this.showValueSetModal = true;
    //     this.setTimeVsetCode = setTimeout(() => {
    //       const vSetData = {
    //         valueSetName: vsetCode,
    //         multiFlag: null
    //       };
    //       this.eventBus.$emit('valueSetCode', vSetData);
    //     }, 0);
    //   },
      // selectedvalueSet(item) {
      //   if(this.vsetCode === appStrings.VALUESETTYPE.ASSET_DASHBOARD_STATUS){
      //       this.selectStatus = {
      //           value: item.value_set_dtl_id,
      //           text:  item.value_code,
      //         };
      //         this.visFlag=true
      //   }
      // },
      // closeValueSetModal() {
      //   this.showValueSetModal = false;
      // },
      validateEmail(){
         if(this.regexPattern.test(this.emailId)){
          this.emailFlag = false;
         }else{
          this.emailFlag=true
         }
        // if(!this.emailFlag){
        //   this.$bvToast.toast('Incorrect email format', {
        //     title: 'Alert',
        //     variant: 'danger',
        //     solid: true
        //   });
        // }
      },
      clearVsetValues(vsetCode) {
        this.vsetCode=vsetCode
        if(this.vsetCode===appStrings.VALUESETTYPE.ASSET_DASH_STATUS){
            this.selectStatus = {
                value: null,
                text: null,
              };
        }
        this.visFlag=false
      },
      alertFunc(content){
        if(this.selectStatus.text==null){
          this.$bvToast.toast(content, {
            title: 'Alert',
            variant: 'danger',
            solid: true
          });
        }
      },
      statusModal(flag){
        this.statusModalFlag=flag
        if(flag){
          this.getAssetStatus()
        }
      },
      getAssetStatus() {
        const payload = {
          valueSetName: "ASSET_DASH_STATUS",
          dependentVariable: {
            search_key: this.searchText,
            _page: this.currentPage - 1
          }
        };
        this.loading = true;
        this.$store
          .dispatch('shared/getCommonVSetData', payload)
          .then(response => {
            if (response.status === 200) {
              this.statusAssetData = response.data.data.page.filter(
                ele=> ele.value_code!==this.status
              );
       
              this.totalRows = response.data.data.total_elements;
              this.visFlag=true
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      },
      selectedAssetStatus(flag, resp) {
        this.statusModalFlag = flag;
        this.selectStatus = resp;
        this.remarks=null;
        this.ticketNumber=null;
        this.employeeId=null;
        this.emailId=null;
      },
      selectedRadio(){
        this.employeeId=null;
        this.remarks=null;
        this.emailId=null;
        this.ticketNumber=null;
      },
      postAssetStatus(){
        this.$v.$touch();
        if (!this.$v.$invalid) {
          if(this.selectStatus.text=="DEPLOYED TO USER" || this.selectStatus.text=="DEPLOYED TO FACILITY"){
            this.payload = { 
            status_id: 0, 
            status: this.selectStatus.text, 
            doc_num: this.assetNum, 
            employee_id: this.employeeId, 
            email: !this.emailFlag?this.emailId:null,
            ticket_number:this.ticketNumber,
            remarks: this.remarks,
            location_id:this.locId
          }
        }else if(this.selectStatus.text == "AVAILABLE"){
          this.payload = { 
            status_id: 0, 
            status: this.selectStatus.text, 
            doc_num: this.assetNum, 
            product_name: this.productName, 
            product_id: this.productId,
            ticket_number:this.ticketNumber,
            remarks: this.remarks,
            location_id:this.locId
          }
        }else{
          this.payload = { 
            status_id: 0, 
            status: this.selectStatus.text, 
            doc_num: this.assetNum, 
            ticket_number:this.ticketNumber,
            remarks: this.remarks,
            location_id:this.locId
          }
        }
        if(((this.payload.status=="DEPLOYED TO USER" || this.payload.status=="DEPLOYED TO FACILITY") && ((this.payload.employee_id==null || this.payload.employee_id=="") || (this.payload.email==null || this.payload.email=="") || (this.payload.ticket_number==null || this.payload.ticket_number==""))) || 
            (this.payload.status=="AVAILABLE" && ((this.payload.product_id==null || this.payload.product_id=="") || (this.payload.product_name==null || this.payload.product_name=="") || (this.payload.ticket_number==null || this.payload.ticket_number==""))) || (this.payload.status=="ALLOCATED" && ((this.payload.remarks==null || this.payload.remarks=="") || (this.payload.ticket_number==null || this.payload.ticket_number=="")))){
              this.$bvToast.toast("Kindly fill the mandatory fields", {
                title: 'Alert',
                variant: 'danger',
                solid: true
              });
        }
        else{
          this.loader = true;
          this.$store
            .dispatch('assets/postAssetStatusDetails', this.payload)
            .then(resp => {
              this.loader = false;
              if (resp.status === 200) {
                this.showAlert = true;
                (this.isSuccess = true);
                (this.responseMsg = resp.data.message);
                this.editMode = false;
                this.totalRows = resp.data.data.total_elements;
                this.$emit('showStatusModal', false);
                this.$bvToast.toast(this.responseMsg, {
                  title: 'Alert',
                  // toaster: 'b-toaster-top-center',
                  autoHideDelay: 5000,
                  variant: 'success',
                  solid: true
                });
              }
              else{
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg = resp.response.data.message;
                this.$bvToast.toast(this.responseMsg, {
                  title: 'Alert',
                  // toaster: 'b-toaster-top-center',
                  variant: 'warning',
                  solid: true
                });
              }
            })
            .catch(() => {
              this.loader = false;
            });
        }
      }
      },
      showHideEmployeeDetailsModal(flag,item){
        this.showHideEmployeeDetailsModalFlag=flag
        if(!flag && item){
          this.employeeId=item.employeeNum
          this.emailId=item.employeeMail
        }
      }

  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
