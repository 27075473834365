import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import addEditAssetLiquidation from './addEditAssetLiquidation';
import HistoryRecord from '@/app/components/onlineApproval/internalWorkFlow/history/historyRecord';
import ApprovalHistory from '@/app/components/onlineApproval/internalWorkFlow/history/approvalHistory';
import UploadDocument from '@/app/components/onlineApproval/internalWorkFlow/history/uploadDocument';
import sendForApproval from '@/app/components/onlineApproval/internalWorkFlow/history/sendForApproval';
import openKmAuthentication from '@/app/components/onlineApproval/internalWorkFlow/openKmAuthentication';

export default {
  name: 'assetLiquidation',
  components: {
    appStrings,
    DatePicker,
    addEditAssetLiquidation,
    HistoryRecord,
    ApprovalHistory,
    UploadDocument,
    sendForApproval,
    openKmAuthentication,
  },
  props: ['currentLoc', 'locId','locAccessFlag'],
  watch: {
    currentPage: function() {
      this.getBatchAssetLiqDetails();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getBatchAssetLiqDetails();
    }
  },
  data() {
    return {
      totalRows: null,
      currentPage: 1,
      loader: false,
      showAlert: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOptionSelectedInvoice(),
      showValueSetModal: false,
      responseMsg: '',
      isSuccess: false,
      batchNumber: null,
      approvalStatus: {
        text:null,
        value:null
      },
      assetLiquidationData: [],
      assetLiquidationFields: [
        {
          key: 'batch_num',
          label: 'Batch Number'
        },
        {
          key: 'approval_status'
        },
        {
          key: 'creation_date',
        },
        {
          key: 'created_by',
        },
      ],
      showAssetLiqModalFlag:false,
      editMode : false,
      fromDate:null,
      toDate:null,
      historyType:null,
      showHistory: false,
      batchId:null,
      showOpenKmModal: false,
      openkmAuthToken:null,
      rowData:null,
      switchFlag:false,
      accessButtons: {},
      updateStatus:null,
      appStatus:null,
    };
  },
  mounted() {
    // this.getBatchAssetLiqDetails();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if(actionName === 'add' ){
          this.updateStatus=null;
          this.switchFlag=false;
          this.rowData=null;
          this.batchId=null
          this.showAssetLiqModal(true)
        }
        else if (actionName === 'save' && !this.showAssetLiqModalFlag) {
          this.editMode = true;
        }
       
      }
    });
  },
  methods: {
    showAssetLiqModal(flag,item){
      this.showAssetLiqModalFlag=flag
      this.getBatchAssetLiqDetails()
      if(flag && !this.locAccessFlag) {
        this.accessButtons = {
          deleteAllow: false,
          downloadAllow: false,
          uploadAllow: false,
          editAllow: false,
          addAllow: false,
          printAllow: false,
          approvalAllow: false
        };
      }
      else if(item && item=='SCRAP'){
        this.accessButtons = {
          deleteAllow: false,
          downloadAllow: false,
          uploadAllow: false,
          editAllow: false,
          addAllow: true,
          printAllow: false,
          approvalAllow: false
        };
      }
      else{
        this.accessButtons = {
          deleteAllow: false,
          downloadAllow: false,
          uploadAllow: false,
          editAllow: true,
          addAllow: true,
          printAllow: false,
          approvalAllow: false
        };
      }
  //     if (flag && this.appStatus==null) {
  //       this.accessButtons = {
  //         deleteAllow: false,
  //         downloadAllow: false,
  //         uploadAllow: false,
  //         editAllow: true,
  //         addAllow: true,
  //         printAllow: false,
  //         approvalAllow: false
  //       };
  //   }else if (flag && this.appStatus=="INPROCESS" || this.appStatus=="APPROVED") {
  //     this.accessButtons = {
  //       deleteAllow: false,
  //       downloadAllow: false,
  //       uploadAllow: false,
  //       editAllow: false,
  //       addAllow: false,
  //       printAllow: false,
  //       approvalAllow: false
  //     }
  // }
    },
    rowSelected(item){
      this.switchFlag=true,
      this.rowData=item,
      this.appStatus=item.approval_status
      this.batchId=item.batch_id
      this.showAssetLiqModal(true,item.approval_status)
    },
    getBatchAssetLiqDetails(item) {
      if(item){
        this.updateStatus="INPROCESS"
    }
      const payload = {
         _page: this.currentPage - 1,
         _limit: this.perPage,
          hr_loc_id: this.locId,
          batch_num:this.batchNumber,
          approval_status:this.approvalStatus.text,
          from:this.fromDate,
          to:this.toDate
        
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getAssetLiqBatchDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.assetLiquidationData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideHistory(flag, historyType) {
        this.showHistory = flag;
        this.historyType = historyType;
    },
    showHideOpenKmModal(flag) {
      // to open km modal
      this.showOpenKmModal = flag;
    },
    setOpenKmToken(token) {
      // to set token in km
      this.showHideOpenKmModal(false);
        this.openkmAuthToken = token;
        this.showHideHistory(true, 'uploadDocument');
      },
      checkOpenKmPasswordSaved() {
        // to check password save or not
        this.loader = true;
        this.$store
          .dispatch('intWorkflow/checkSavedPassword')
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              if (response.data.data) {
                this.getOpenKmAuthToken();
              } else {
                this.showHideOpenKmModal(true);
              }
            }
          })
          .catch(() => {
            this.loader = false;
          });
      },
      getOpenKmAuthToken() {
        // to get auth token for open km
        const payload = {
          password: null,
          saved_password_flag: true,
          user_name: null
        };
        this.loader = true;
        this.$store
          .dispatch('intWorkflow/openKmAuthenticate', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.openkmAuthToken = response.data.data.token;
                this.showHideHistory(true, 'uploadDocument');
              }
          })
          .catch(() => {
            this.loader = false;
          });
      },
      openValueSetModal(vsetCode) {
        this.vsetCode = vsetCode;
        this.showValueSetModal = true;
        this.setTimeVsetCode = setTimeout(() => {
          const vSetData = {
            valueSetName: vsetCode,
            multiFlag: null
          };
          this.eventBus.$emit('valueSetCode', vSetData);
        }, 0);
      },
      selectedvalueSet(item) {
        if (this.vsetCode === appStrings.VALUESETTYPE.APPROVAL_STATUS) {
          this.approvalStatus.text = item.value_code;
          this.approvalStatus.value = item.value_set_dtl_id;
        }
      },
      closeValueSetModal() {
        this.showValueSetModal = false;
      },
      clearVsetValues(vsetCode) {
        this.vsetCode = vsetCode;
        if (this.vsetCode === appStrings.VALUESETTYPE.APPROVAL_STATUS) {
          this.approvalStatus.text = null; 
          this.approvalStatus.value = null;
        }
      },
      clearUser(){
        this.batchNumber=null;
        this.approvalStatus={
          text:null,
          value:null
        };
        this.fromDate=null;
        this.toDate=null;
      },
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
